import { React } from "react";
import WorldPhoneLogo from "../src/Assets/Logo-Worldphone-300x114.png";
import Logo from "../src/Assets/WorldMaxxLogo.svg";
import "./App.css";
import ContactModal from "./Components/ContactModal";

const App = () => {
  return (
    <>
      {/* <ToastContainer /> */}

      <div
        style={{ height: "8vh" }}
        className="px-2 py-3 bg-white flex justify-between"
      >
        <div className="flex justify-center">
          <img
            className="h-10 xl:w-auto sm:w-[70px]  sm:h-10 mx-2 pt-2"
            src={WorldPhoneLogo}
            alt=""
          />
          <img
            className="h-10 xl:w-auto sm:w-[70px]  sm:h-10"
            src={Logo}
            alt=""
          />
        </div>
        <div>{/* <ContactModal header={true} /> */}</div>
      </div>

      <div
        className="mb-10"
        style={{
          backgroundColor: "#F7F7F7",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "92vh",
          width: "100vw",
          flexDirection: "column",
        }}
      >
        <div className="d-flex min-w-[300px] w-[350px] bg-red-200 p-10 rounded-2xl">
          <div className="flex justify-center flex-1">
            <img
              className="h-10 w-auto sm:h-10 mx-2 pt-2"
              src={WorldPhoneLogo}
              alt=""
            />
            <img className="h-10 w-auto sm:h-10" src={Logo} alt="" />
          </div>
          <div className="mt-6">
            <h1 className="text-red-900 text-center">
              Please choose your location
            </h1>
          </div>
          <div
            className="mt-10 cursor-pointer"
            onClick={() => {
              window.location.href =
                "https://maxxfibernet.xceednet.com/subscriber/sessions/new";
            }}
          >
            <div className="w-full  flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700">
              Jalalpur
            </div>
          </div>
          <div
            onClick={() => {
              window.location.href =
                "https://pkwifi.xceednet.com/subscriber/sessions/new";
            }}
            className="mt-10 cursor-pointer"
          >
            <div className="w-full  flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700">
              Akbarpur
            </div>
          </div>
          <div
            onClick={() => {
              window.location.href =
                "https://maxxairfiber.xceednet.com/subscriber/sessions/new";
            }}
            className="mt-10 cursor-pointer"
          >
            <div className="w-full  flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700">
              WireLess (AirFiber)
            </div>
          </div>
        </div>
        <div className="mt-5 m-5">
          <h1 className="text-white text-center bg-red-600 p-2 rounded-md ">
            NOTE: To get your username and password, kindly WhatsApp your name
            and registered mobile number at{" "}
            <span
              className="cursor-pointer"
              onClick={() => {
                window.location.href = "https://wa.me/+917081136926";
              }}
            >
              +91-7081136926
            </span>
          </h1>
        </div>
        <div className="mx-5">
          <h1 className="text-white text-center bg-red-600 p-2 rounded-md ">
            For any complaints related to{" "}
            <span className="font-black text-xl">Jalalpur</span>, please contact
            the following number:
            <span
              className="cursor-pointer"
              onClick={() => {
                window.location.href = "tel:+91-8484837011";
              }}
            >
              +91-8484837011
            </span>
          </h1>
        </div>
        <div className="mx-5">
          <h1 className="text-white text-center bg-red-600 p-2 rounded-md mt-4">
            For any complaints related to{" "}
            <span className="font-black text-xl">Akbarpur</span>, please contact
            the following number:
            <span
              className="cursor-pointer"
              onClick={() => {
                window.location.href = "tel:+91-7007242003";
              }}
            >
              +91-7007242003
            </span>
          </h1>
        </div>
      </div>
    </>
  );
};

export default App;
